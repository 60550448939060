import React from 'react';
import { Card, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ReactDatatable from '@ashvin27/react-datatable'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { CKEditor } from 'ckeditor4-react';
import Lod from '../../assets/img/tim_80x80.png'
import {PromotionAddEdit,ListOfAllProm,DeleteRecFromToken} from '../../actions/users'
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import config from '../../lib/config.js'
import ReactHTMLParser from 'react-html-parser'
const useStyles     = makeStyles(styles);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
const ADMINPROMOTION    =   ()  =>  {
    const {pathname , state}    =   useLocation()
    const history       =   useHistory()
    const classes                   = useStyles();
    const   [   ShowPage    ,   SetShowpage    ]    =   useState('list')
    const   [   Details     ,   SetDetails     ]    =   useState([])
    const   [   AddData     ,   SetAddDat      ]    =   useState({'_id':'','image':Lod,'contents':''})
    
    const columns = [
        {
            key: "",
            text: "SNO",
            align: "left",
            sortable: false,
            cell:(records,index)=>{
               return <>{index+1}</>
            }
          },
        
          {
            key: "contents",
            text: "Contents",
            align: "left",
            sortable: false,
            cell:(records,index)=>{
              return <div dangerouslySetInnerHTML={{__html: records.contents}}></div>
           }
          },
          {
            key: "",
            text: "Actions",
            align: "left",
            sortable: false,
            cell:(records,index)=>{
             return <>
              <button
                      data-toggle="modal"
                      data-target="#update-template-modal"
                      className="btn btn-primary btn-sm"
                      onClick={() => history.push({pathname:'/adminpromotionedit',state:records})}
                     
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button
                      className="btn btn-danger btn-sm mr-1"  
                      onClick={() => deleteR(records._id)}>
                      <i className="fa fa-trash"></i>
                  </button>
              </>
           }
          },
    ]

    const deleteR = async(id) => {
      console.log("_id",id)
      const resp  = await DeleteRecFromToken({id})
      if(resp?.data?.success == 'success')
      {
        toast.success(resp.data.data)
        window.location.reload()
      }
      else{
        toast.error(resp.data.data)
      }
    }
    
    useEffect(()=>{
        if(pathname.includes('edit')) {
          SetShowpage("Edit")
          console.log("state",state)
          if(state) {SetAddDat(state)}
        }
        else if(pathname.includes('add')) SetShowpage('add')
        else  { 
            SetShowpage('list') 
            ListOfAll()
            }
    },[pathname,state])
    
    const ListOfAll = async() =>{
      const Resp  = await ListOfAllProm()
      if(Resp?.data) 
      SetDetails(Resp.data.data)
    }
    const onEditorChange = useCallback((evt,data) => {
            console.log("evt",evt)
            const id = 'contents'
            var description_text = evt.editor.getData() 
            var formDat =   { ...AddData , ...{ [id] : description_text } }
       
        SetAddDat(formDat)
        
    },[AddData])
    
    console.log("content change chk outsid", AddData)
    const handleSubmit  =   async()  =>  {
        const Exe   =   await PromotionAddEdit(AddData)
        if(Exe?.data?.success == 'success')
          {
            history.push('/adminpromotionlist')
          }
          toast.success(Exe?.data?.data)
        console.log(Exe)
        
    }

    return(
        ShowPage == 'list'  ?
        <div>
            <div className="page_header">
                <h2>NFT Tag List</h2>
            </div> 
           
            <div> <Button className={'primary'} 
            onClick={()=>history.push('/adminpromotionadd')}>
              Add</Button></div>
      <Paper>    
           <ReactDatatable
                records={Details}
                columns={columns}
              />
      </Paper>
      </div>
     :
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <div className={classes.form} >
              <CardHeader color="primary">
                <h4 className={'mt-4'}> {ShowPage == 'add' ? 'Add' :'Edit'} Promotion</h4>
              </CardHeader>
              <CardBody>
              <GridContainer>                 
                  <GridItem xs={12} >
                  {<CKEditor
                            id = "contents"
                            value={AddData.contents}
                            data={AddData.contents}
                            initData={<p>{ReactHTMLParser(AddData.contents)}</p>}
                            onChange={(e)=>onEditorChange(e,'editor')}
                        />
                    }
                  </GridItem>
                </GridContainer>
               
               
              
              </CardBody>
              <CardFooter>
                <Button 
                    color   =   "primary" 
                    type    =   "submit"
                    onClick =   {handleSubmit}
                    >
                        {ShowPage == 'add'?'Add':'Edit'}
                    </Button>
              </CardFooter>
            </div>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
   
    )
}
export default ADMINPROMOTION