
let key = {};
var EnvName = 'production';
let IPFS_IMG = "https://ipfs.ipfs.io/ipfs"
var networkVersion=''
var BNBProvider="";
let Back_Url=""
let decimalValues = 1000000000000000000;
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
 if(EnvName === "production") {
   
    var exchangeAddress = "";
    var adminaddress = "";
    var singleContract="0xD1d818dF0041d78f7D829E7B488f96A460137690".toLowerCase();
    var multipleContract="0xB1A35f5F95880ebd26a6D855685eDF0E07065e4d".toLowerCase();
    var trade = ("").toLowerCase();
    var API_URL = 'https://api.bidpixels.com';
    var IMAGE_URL = 'https://api.bidpixels.com/images/user';
    var PORT = 2053;
    Back_Url='https://api.bidpixels.com'
    networkVersion='56';
    BNBProvider="https://bsc-dataseed1.binance.org/";
    var Front_URL="https://contorls.bidpixels.com"

}
else if(EnvName === "local") {
    var exchangeAddress = "0x3451A375938421a1892482A558316BA336C20Acb";
    var adminaddress = "";
    var singleContract="0xd2804aeAcb81a1704F990550b94dbB87d9Ff1Bc1";
    var multipleContract="0xb2DB53CF39d366CF01EA79c7B25c5D3DF0Bf506D";
    var trade = ("0xFAF9Bc294F92F6312bbE23039128881Cf16D02e4").toLowerCase();
    var API_URL = 'http://localhost:2002';
    var IMAGE_URL = 'http://localhost:2002/images/user';
    var PORT = 2002;
    Back_Url='http://localhost:2002'
    networkVersion='97';
    BNBProvider="https://data-seed-prebsc-1-s1.binance.org:8545/";
    var Front_URL="http://localhost:3001/metitslexipdib"
}

key = {
    secretOrKey: "",
    Recaptchakey: "",
    // API:`${API_URL}:${PORT}/v1`,
    API:`${API_URL}/v1`,
    IMAGE_URL:IMAGE_URL,
    exchangeAddress:exchangeAddress,
    toasterOption:toasterOption,
    IPFS_IMG:IPFS_IMG,
    networkVersion:networkVersion,
    adminaddress:adminaddress,
    decimalValues:decimalValues,
    Back_Url:Back_Url,
    singleContract:singleContract,
    multipleContract:multipleContract,
    networkVersion:networkVersion,
    BNBProvider:BNBProvider,
    Front_URL:Front_URL,
    trade:trade
};

export default key;